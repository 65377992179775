.events-list {
  .main-date {
    color: $ibero-light-blue;
    font-weight: 900;
  }

  .event {
    border-bottom: 1px solid var(--ibero-yellow);

    &:last-child {
      border-bottom: none;
    }

    .button {
      @include button;
	  display: inline-flex;
      text-decoration: none;
    }

    .event-info {
      padding-bottom: 1.5rem;
    }
  }
}
