.person {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: .75rem;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &__image {
	border-radius: 50%;
	width: 6rem;
  }

  &__info {
	align-self: center;

	&__name,
	&__phone,
	&__mail,
	&__address {
	  display: block;
	}

	&__mail {
	  @include button;
	  display: inline-flex;
	  padding-top: .75rem;
	  padding-bottom: .75rem;
	  margin-top: .75rem;
	  text-decoration: none;
	}
  }
}
