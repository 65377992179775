.swiper-container {
  width: 100%;
  //height: 100%; to work outside columns

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
