h1 {
  font-size: 2rem;
  color: $ibero-dark-blue;

  @include breakpoint(xlarge) {
    font-size: 2.375rem;
  }
}

h2,
.h2 {
  font-size: 1.75rem;
}

