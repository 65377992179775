footer {
  margin-top: 1rem;

  .footer {
	padding-bottom: 1rem;
	background: $ibero-dark-blue;
  }

  svg {
	margin-bottom: -.5rem;

	path {
	  fill: $ibero-dark-blue;
	}
  }

  .footer__info {
	display: flex;
	flex-direction: column;
	color: white;

	&-list { //ul
	  @include reset-ul('row');

	  &__item {
		margin-left: 1rem;

		&:first-child {
		  margin-left: 0;
		}

		&__anchor {
		  color: white;
		}
	  }
	}
  }

  @include breakpoint(xlarge) {
	margin-top: 3rem;

	.footer {
	  padding-bottom: 2rem;
	}

	.footer__info {
	  justify-self: center;
	  flex-direction: row;

	  &-list { //ul

		&:before {
		  content: ' | ';
		  margin-left: 1rem;
		  margin-right: 1rem;
		}

		&__item {
		  &__anchor {
			@include anchor;
		  }
		}
	  }
	}
  }
}
