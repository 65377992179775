.social-media {
  @include vertical-padding;

  &__meta-title {
	text-align: center;
	font-weight: 900;
  }

  &__links {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1.5rem;

	&__item {
	  grid-row: 1;

	  &__image {
		height: 3.475rem;
		width: auto;
	  }
	}

	@include breakpoint(xlarge) {
	  display: grid;
	}
  }
}
