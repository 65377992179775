:root {
  --ibero-green: #107d5d;
  --ibero-dark-blue: #16384c;
  --ibero-blue: #1c6079;
  --ibero-light-blue: #2c7199;
  --ibero-light-gray: #F8F9FA;
  --ibero-black: #383E42;
  --ibero-yellow: #F1C12B;

  --grid-margin: 1.5rem;
  --grid-max-width: 102rem;
  --grid-columns: 4;

  @include breakpoint(medium) {
    --grid-columns: 6;
    --grid-margin: 2rem;
  }

  @include breakpoint(xlarge) {
    --grid-columns: 12;
  }

  @include breakpoint(xxlarge) {
    --grid-columns: 12;
    --grid-margin: 6rem;
  }
}

$animation-time: 400ms;
$gap: 1.5rem;

$ibero-green: #107d5d;
$ibero-dark-blue: #16384c;
$ibero-blue: #1c6079;
$ibero-light-blue: #2c7199;
$ibero-light-gray: #F8F9FA;
$ibero-black: #383E42;
$ibero-yellow: #F1C12B;

$ibero-light-orange: #F29D34;
$ibero-light-red: #F05353;
$ibero-orange: #F27649;
$ibero-light-green: #10A697;

$header-and-svg: white;// #f8f8f8; //transparent;// #faf4de;// rgb(255, 237, 168);// lighten($ibero-yellow, 42%);
