.columns {
  @include vertical-padding;

  @include breakpoint(xxlarge) {
	.layout__grid--column-50 {
	  grid-column: 2/7;

	  + .layout__grid--column-50 {
		grid-column: 7/12;
	  }
	}
  }

  @include breakpoint(xlarge) {
	.layout__grid--column-50:only-child {
	  grid-column: 4/10;
	}
  }
}
