header {
  $self: &;
  $header-height: 3rem;
  //box-shadow: 0 3px 6px #99999982;

  .header__quick-infos {
	padding-bottom: .5rem;
	padding-top: .5rem;
	background-color: $ibero-green;

	&-address,
	&-phone {
	  font-size: .85rem;
	  display: flex;
	  justify-self: flex-end;
	  align-items: center;

	  svg {
		margin-right: .5rem;

		.inner {
		  fill: white;
		}
	  }
	}

	&-address {
	  display: none;
	  justify-self: end;
	  grid-row: 1;
	}

	&-phone {
	  @include anchor;
	  color: white;
	  grid-row: 1;
	}
  }

  .main-header {
	background-color: $header-and-svg;
	padding-top: $gap * 1.5;
	transition: background $animation-time;
	position: relative;
	z-index: 2;

	//@include vertical-padding(false);
  }
  .logo, .veggie-burger {
	//grid-row: 1/2;
  }

  .logo {
	grid-column: 1/3;
	//height: $header-height;
	align-self: center;

	&__anchor { // specially for keyboard tabs (box-shadow, outline)
	  height: 100%;
	  display: block;

	  &:hover {
		svg {
		  g {
			path.accent {
			  fill: $ibero-light-orange;
			}
		  }

		  g.world {
			path {
			  fill: $ibero-light-orange;
			}
		  }
		}
	  }
	}

	&__svg {
	  display: flex;
	}

	svg {
	  height: 100%;

	  g {
		transition: transform 450ms;
	  }

	  g.america {
		transform: translateX(-100vw);
	  }

	  path {
		transition: fill 450ms;
	  }

	  path.accent {
		display: none;
	  }

	  .ibero-color-1 {
		fill: var(--ibero-green);
	  }

	  .ibero-color-2 {
		fill: var(--ibero-light-blue);
	  }

	  .ibero-color-1,
	  .ibero-color-2 {
		transition: fill $animation-time;
	  }
	}
  }

  .skip-navigation {
	position: absolute;
	left: 0;
	width: 7rem;
	top: -70vh;
	text-decoration: none;

	@include button;

	&:focus {
	  top: 0;
	  bottom: 0;
	}
  }

	.veggie-burger {
	grid-column: 4/5;

	@include breakpoint(medium) {
	  grid-column: 6/6;
	}

	background: transparent;
	border: 0 solid transparent;
	width: $header-height;
	height: $header-height;
	padding: .3rem;
	align-self: start;
	justify-self: end;

	&:focus {
	  outline: 0;
	  box-shadow: 0 0 6px 0 var(--ibero-blue);
	  border-radius: 6px;
	}

	.button-line {
	  fill: none;
	  stroke: var(--ibero-green);
	  stroke-linecap: round;
	  stroke-miterlimit: 7.5;
	  stroke-width: 7.5px;
	  transition: transform $animation-time, opacity $animation-time;

	  &__1 {
		transform-origin: 0 16px;
	  }
	  &__3 {
		transform-origin: 7.5px 32px;
	  }
	}

	&-title {
	  display: none;
	}

	// Menu is open
	&[aria-expanded="true"] {

	  .button-line {
		stroke: var(--ibero-light-gray);
	  }

	  .button-line__1 {
		transform: rotate(45deg);
	  }

	  .button-line__2 {
		opacity: 0;
	  }

	  .button-line__3 {
		transform: rotate(-45deg);
	  }

	  + .main-menu { // opened menu
	  }

	}
  }

  .decoration-background {
	z-index: -1;
	margin-top: -1px;
	margin-left: -7px; // TODO check svg position

	path {
	  transition: fill $animation-time;
	  fill: $header-and-svg;
	}
  }

  @include breakpoint(xlarge) {
	.veggie-burger {
	  display: none;
	  grid-column: 12/12;
	}

	.header__quick-infos {
	  display: block;
	  color: white;
	  font-weight: 900;

	  &-address {
		display: flex;
	  }
	}

	.logo {
	  svg {
		g.america {
		  transform: translateX(0);
		}

		path.accent {
		  display: inline;
		}
	  }
	}
  }

  @include breakpoint(xxxlarge) {
	//.america {
	//  display: block;
	//  align-self: center;
	//  height: 3.4rem;
	//  margin-top: -8.4px;
	//  margin-left: -1.3rem;
	//
	//  svg {
	//	height: 100%;
	//  }
	//}

	padding-bottom: 2rem;
	box-shadow: 0 0 1rem 0 lightgrey;
	margin-bottom: 2rem;

	.decoration-background {
	  display: none;
	}
  }
}
