.main-menu {
  $self: &;

  @mixin opened-submenu { // for xlarge
	display: block;
	max-height: none;
	min-width: 16rem;
	background: var(--ibero-light-gray);
	border-top: .25rem solid var(--ibero-yellow);
	padding: .5rem 1rem;
	//border-radius: .5rem;

	li:last-child {
	  border-bottom: none;
	}
  }

  @mixin active_item {
	font-weight: 900;
	background-color: $ibero-light-green;
	color: white;
	//border-radius: 3rem;

	#{$self}__item {
	  //border-radius: .5rem;
	}

	> #{$self}__item__anchor {
	  color: white;
	}

	#{$self}__expand-sub-menu { // button to open submenus (or sub-sub)
	  svg {
		line {
		  //stroke: white;
		}
	  }
	}
  }

  --menu-item-background: white;
  --menu-active-item-background: var(--ibero-yellow);
  --menu-item-hover-background: var(--ibero-blue);
  --menu-item-color: var(--ibero-dark-blue);
  --menu-item-border-color: var(--ibero-light-blue);

  $menu-active-item-background-large: lighten($ibero-yellow, 25%);
  $menu-active-item-color-large: $ibero-light-blue;
  $menu-item-padding: 1.25rem;
  $submenu-item-vertical-margin: 0;
  $submenu-item-horizontal-margin: 0;
  $button-size: 2.75rem;

  transition: transform $animation-time 200ms, overflow $animation-time, height $animation-time;
  transform: translateY(-100vw);
  top: 2.5rem;
  margin-left: -$gap;
  margin-right: -$gap;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  background: white;
  overflow: hidden;
  grid-column: 1;
  grid-row: 2/2;
  opacity: 0;

  header .veggie-burger[aria-expanded="true"] + & {
	transition: transform $animation-time, overflow $animation-time, height $animation-time, opacity $animation-time 300ms;
	overflow: visible;
	transform: translateY(1px);
	margin-left: calc(var(--grid-margin) * -1);
	margin-right: calc(var(--grid-margin) * -1);
	height: 100vh;
	opacity: 1;
  }

  &__list { // ul of main menu (first level)
	border-bottom: 2px solid var(--ibero-dark-blue);
  }

  &__list,
  &__sublist,
  &__language-chooser {
	@include reset-ul;
  }

  &__sublist { // ul of submenu (second level)
	transition: max-height $animation-time;

	#{$self}__item {
	  padding-left: 1rem;

	  a {
		font-weight: 300;
	  }
	}

	&.sub-menu-ready {
	  transition: max-height $animation-time;
	  max-height: 0;
	  overflow: hidden;
	}

	&.open {
	  transition: max-height $animation-time;
	  visibility: visible;
	  max-height: 100vh;
	  z-index: 2;
	}
  } // end of ul of submenu (second level)

  &__item { // li
	background-color: var(--menu-item-background);
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--menu-item-border-color);

	&:last-child {
	  border-bottom: none;
	}

	#{$self}__expand-sub-menu { // button to open submenus (or sub-sub)
	  background-color: white;//lighten($ibero-yellow, 40%);
	  border-radius: 50%;
	  width: $button-size;
	  height: $button-size;
	  cursor: pointer;
	  align-self: center;
	  justify-self: self-start;

	  svg {
		transition: transform $animation-time/2;
	  }
	} // end of button to open submenu

	&.ancestor { // li: indicator of active subpage

	  #{$self}__expand-sub-menu {
		border-color: var(--menu-active-item-background);

		svg {
		  //opacity: 0;
		}
	  }

	 .sub-menu-ready {
	   //max-height: 100vh; // when subpage is active, ancestor (parent page) is not able to close/open
	 }

	} // end of li: indicator of active subpage

	&__anchor { // anchor inside li
	  text-transform: uppercase;
	  text-decoration: none;
	  font-weight: bold;
	  padding: $menu-item-padding;
	  color: var(--menu-item-color);

	  &:hover, &:focus {
		//transition: background-color $animation-time;
		//background-color: var(--menu-item-hover-background);
	  }
	} // end of anchor inside li

	&--active { // li active: no anchor inside, only span
	  &__visually-hidden {
		display: none;
	  }

	  &__current-page { // li active without anchor (span)
		text-transform: uppercase;
		padding: $menu-item-padding;
		display: block;
		position: relative;
		//background-color: var(--menu-active-item-background);
		&-marker {
		  display: block;
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: -1rem;
		  width: 1.5rem;
		  background-color: var(--menu-active-item-background);
		}

	  }
	} // end of li active

	&--has-children { // li that has a button and ul
	  display: grid;
	  grid-template-columns: auto 3rem;
	  grid-template-rows: auto auto;

	  .main-menu__expand-sub-menu[aria-expanded="true"] {
		svg {
		  transform: rotate(180deg);
		}
	  }

	  .main-menu__item { // check if this is correct
		&:first-child {
		  border-top: 1px solid var(--menu-item-border-color);
		}
	  }

	  a,
	  button {
		grid-row: 1/2;
	  }

	  a {
		grid-column: 1/2;
	  }

	  button {
		background: var(--ibero-light-gray);
		border: 0;
		grid-column: 2/3;
	  }

	  ul {
		grid-row: 2/3;
		grid-column: 1/3;
	  }

	  &.language-chooser-menu { // first level (showing active language)
		.language-chooser-menu-active-language {

		}

		.language-chooser-icon {
		  display: none;
		}

		.language-chooser { // ul of language to choose from
		  #{$self}__language-chooser__item--active {
			// do not display active language on language menu (again)
			display: none;
		  }
		}
	  }
	} // end of li that has children: a button and ul
  }

  ///////////////////////////
  // BEGIN OF LARGE STYLE
  ///////////////////////////

  @include breakpoint(xlarge) {
	margin-left: 0;
	margin-right: 0;
	background: transparent;
	position: static;
	opacity: 1;
	transform: translateY(0);
	z-index: 0;
	grid-column: 3/13;
	grid-row: 1;
	display: grid;
	grid-auto-flow: column;
	overflow: visible;

	&__list {
	  > #{$self}__item {
		&--active {
		  border-radius: 3rem;
		}

		&:hover {
		  border-radius: 3rem;
		}
	  }
	}

	&__list { // ul (desktop main menu)
	  display: grid;
	  grid-auto-flow: column;
	  grid-auto-columns: auto;
	  justify-self: end;
	  border: none;
	  margin-right: -1.15rem; // compensate the grid (last item dropdown arrow has significant padding)
	}

	&__item {
	  border-bottom: none;
	  background: transparent;

	  &--active { // li active: no anchor inside, only span
		@include active_item;

		&__current-page-marker {
		  display: none;
		}
	  }

	  &__anchor {
		//@include button;
	  }

	  &.ancestor { // li: indicator of active subpage
		#{$self}__expand-sub-menu {
		  //background-color: transparent;
		}
	  }

	  &:not(.language-chooser-menu):hover {
		@include active_item;
		background-color: $ibero-light-blue;
	  }
	}

	&__sublist { // ul ul etc (desktop popup submenus)
	  box-shadow: 0 11px 20px 0 #7d7d7d;
	  display: none;
	  position: absolute;
	  left: 0;
	  right: 0;

	  &.sub-menu-ready {
		max-height: none;
	  }

	  &.open { // when button for opening children is triggered
		@include opened-submenu;
	  }

	  #{$self}__item {
		padding-left: 0;
		border-bottom: 1px solid var(--ibero-dark-blue);

		&__anchor {
		  font-size: 1rem;
		  margin: $submenu-item-vertical-margin $submenu-item-horizontal-margin;
		  transition: background-color $animation-time, color $animation-time;

		  &:hover {
			//background-color: $menu-active-item-background-large;
			//color: $menu-active-item-color-large;
		  }
		}

		&--active {
		  &__current-page {
			//background-color: $menu-active-item-background-large;
			//color: $menu-active-item-color-large;
			//margin-left: $submenu-item-horizontal-margin;
		  }
		}

		&--has-children {
		  #{$self}__sublist { // ul ul ul
			position: static;
			margin-left: -1rem;
			margin-right: -1rem;
			box-shadow: none;
			border-bottom: .25rem solid var(--ibero-yellow);
			margin-bottom: -1px;
		  }
		}
	  }
	} // end of desktop popup submenus

	&__item--has-children { // li with children
	  position: relative;
	  grid-template-rows: auto;

	  #{$self}__item {
		&:first-child {
		  border-top: none;
		}

		&--active {
		  &__current-page {
			//@include button;
			//font-weight: 900;
			//text-decoration: underline;
		  }
		}
	  }

	  #{$self}__expand-sub-menu {
		//background: transparent;
		svg {
		  max-width: .75rem;

		  path {
		  }
		}
	  }

	}

	> #{$self}__list > #{$self}__item--has-children:hover { // open only first submenu on hover on large (sublist)
	  > #{$self}__expand-sub-menu { // indicate icon
		svg {
		  transform: rotate(180deg);
		}

		@at-root body:not(.sub-menu-is-open) &:hover {
		  svg { // hovering on icon closes submenu on hover because user can open it on click
			transform: none;
		  }

		  + #{$self}__sublist:not(.open) {
			display: none // hovering on icon closes submenu on hover because user can open it on click;
		  }
		}
	  }

	  > #{$self}__sublist {
		@include opened-submenu;
	  }
	} // end of hover

	&__language-chooser {
	  flex-direction: row;
	  justify-content: flex-end;
	  align-items: center;

	  &__item:hover .main-menu__item__anchor {
		//border-radius: .5rem;
	  }
	}

	.language-chooser-menu { // consider turn it to BEM
	  //max-width: 7rem;
	  //justify-self: end;

	  > #{$self}__item--active__current-page {
		//padding: 5px;
		display: flex;
		align-items: center;
		font-weight: bold;
		flex-direction: row;
		color: $ibero-light-blue;
		padding-right: .25rem;

		.language-chooser-menu-active-language { // consider turn it to BEM
		  //display: none;
		}
	  }

	  .language-chooser-icon {
		display: block;
		width: 1.5rem;
		margin-left: .5rem;

		#world {
		  fill: var(--ibero-green)
		}

		.ring {
		  fill: var(--ibero-yellow)
		}
	  }

	  .language-chooser {
		left: auto;
		min-width: 17rem;
	  }
	}
  } // end of xlarge styles
}
