html {
  height: 100%;
  font-family: 'Poppins', Arial, serif;
  line-height: 1.5;
  color: var(--ibero-blue);
  hyphens: auto;

  &.menu-is-open {
    //background: var(--ibero-light-blue);
    header {
      .decoration-background { // refer to header
        opacity: 1;

        path {
          fill: var(--ibero-light-blue);
        }
      }

      .main-header {
        background: var(--ibero-light-blue);
        z-index: 1;

        .decoration-background g path {
          fill: var(--ibero-light-blue);
        }
      }
    }

    .logo svg path {
      fill: var(--ibero-light-gray);
    }

    & main {
      > * {
        opacity: 0;
      }

    }
  }

  body {
    height: 100%;
    display: grid;
    grid-template-areas: "header"
                         "main"
                         "footer";
    grid-template-rows: auto 1fr auto;

    &.sub-menu-is-open main { // we just need it for xlarge
      z-index: -1;
    }

  }

  header {
    grid-row: header/header;
  }

  main {
    grid-row: main/main;

    section {
      //padding: $gap;
    }

  }

  footer {
    grid-row: footer/footer;
  }

  a,
  button {
	@include focus-outline;
  }
}

.layout {
  width: 100%;
  margin: 0 auto;
  padding-left: var(--grid-margin);
  padding-right: var(--grid-margin);
  max-width: var(--grid-max-width);
  box-sizing: border-box;

  &--zero {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }

  &__grid {
    position: relative;
    width: 100%;
    display: grid;
    grid-gap: $gap;
    row-gap: 0;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    grid-template-rows: auto;

    &--column-100 {
      position: relative;
      grid-column: 1 / -1;
	}

    &--column-50 {
      grid-column: span 6;

      //@include breakpoint(xlarge) {
      //  grid-template-columns: repeat(2, 1fr);
      //};
    }
  }

  > .layout {
    padding-left: 0;
    padding-right: 0;
  }
}

.page-title {
  text-align: center;
  color: $ibero-light-blue;

  @include breakpoint(xlarge) {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }

  &:after {
    $after-headline-width: 6rem;
    content: "";
    height: .5rem;
	width: $after-headline-width;
    display: block;
    position: relative;
    background-color: var(--ibero-yellow);
	left: calc(50% - #{$after-headline-width}/2);
    margin-top: .75rem;
	border-radius: .5rem;
  }
}

p {
  margin-bottom: 1.5rem;
  margin-top: 0;
  font-size: 1.15rem;
  line-height: 2;

  &:last-child {
    margin-bottom: 0;
  }
}

// Text plugin
.text,
.events-list {
  ul {
    li {
      list-style-type: none;
      position: relative;
      line-height: 1.5;
      margin-bottom: .75rem;

      &::before {
		content: "";
        position: absolute;
		left: -2rem;
        top: 0;
        width: 1.5rem;
        height: 1.5rem;
        background-image: url(../../static/img/check-red.svg);
        background-repeat: no-repeat;
        }
      }
    }
}

.linked-picture {
  transition: box-shadow 450ms;
  display: block;
  border-radius: 1rem;

  img {
    border-radius: 1rem;
  }

  &:hover {
    box-shadow: 5px 5px 1rem 1px rgba(0, 0, 0, .5);
  }
}
.section-1 {
  background: $ibero-blue;
  color: #fff;
}

.section-2 {
  background: lighten($ibero-blue, 5%);
  color: #fff;
}

.section-3 {
  background: lighten($ibero-blue, 8%);
  color: #fff;
}
